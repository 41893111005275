import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import GalleryItem from "../components/gallery-item"
import Grid from "../components/grid"
import { Helmet } from "react-helmet"
import Narrow from "../components/narrow"

const StyledDrawingsPage = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  h1 {
    margin: 3rem 0 0 0;
    text-transform: uppercase;
    font-weight: 300;
  }
`

const DrawingsPage = () => {
  const [active, setActive] = useState(null)
  const query = useStaticQuery(graphql`
    query {
      contentfulTeikningar {
        galleryImages {
          id
          lowRes {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          fullRes {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const toggleActive = index => {
    if (index !== active) {
      setActive(index)
    } else {
      setActive(null)
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Agnes Suto | Teikningar</title>
      </Helmet>
      <StyledDrawingsPage>
        <h1>Teikningar</h1>
        <Narrow>
          <Grid>
            {query.contentfulTeikningar.galleryImages.map((image, index) => (
              <GalleryItem
                image={image}
                active={active === index}
                alt=""
                key={image.id}
                onClick={() => {
                  toggleActive(index)
                }}
              />
            ))}
          </Grid>
        </Narrow>
      </StyledDrawingsPage>
    </Layout>
  )
}

export default DrawingsPage
